/* 广告组件 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import errorBoundary from '@ifeng/errorBoundary';
import Ad from '@ifeng/ui_pc_ad';
import styles from './index.css';

class AdComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.any,
        componentOptions: PropTypes.object,
    };

    render() {
        const { content, componentOptions } = this.props;
        const dataKey = componentOptions.dataKey || 'allData';
        const ad = typeof window === 'object' ? window[dataKey][content] : undefined;

        // dataKey是数据来源，默认是allData
        // content是从allData里查已格式化的数据用的
        // console.log('AdComponent', content, ad);

        return (
            <NoSSR onSSR={<div className={styles.ad} />}>
                <Ad styleName={styles.ad} content={ad} />
            </NoSSR>
        );
    }
}

export default errorBoundary(AdComponent);
