import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Ad from '@ifeng/ui_pc_ad';
import styles from './index.css';

class AdRegion extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content = {} } = this.props;
        const { float1Ad, float2Ad, coupletAd, rightBottomAd, popunderAd, triggerLeftAd } = content;

        return Object.keys(content).length > 0 ? (
            <div className={styles.adregion}>
                {float1Ad ? <Ad content={float1Ad} /> : null}
                {float2Ad ? <Ad content={float2Ad} /> : null}
                {coupletAd ? <Ad content={coupletAd} /> : null}
                {rightBottomAd ? <Ad content={rightBottomAd} /> : null}
                {popunderAd ? <Ad content={popunderAd} /> : null}
                {triggerLeftAd ? <Ad content={triggerLeftAd} /> : null}
            </div>
        ) : null;
    }
}

export { AdRegion };
export default AdRegion;
