/* 轮播组件 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Event as UiBaseEvent } from '@ifeng/ui_base';
import Slides from '@ifeng/ui_pc_slides';
import errorBoundary from '@ifeng/errorBoundary';
import { formatUrl, formatImage } from '@ifeng/public_method';
import styles from './index.css';
import { rel as relText } from '../../../utils/rel';
import { handleAd } from '../../../utils/handleAd';

class SliderComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
        componentOptions: PropTypes.object,
    };

    constructor(...argv) {
        super(...argv);

        const {
            content,
            componentOptions: { extendData, length },
        } = this.props;
        const formatData = this.formatData(content, extendData, length);

        this.state = {
            data: formatData,
        };
    }
    componentDidMount() {
        const { componentOptions } = this.props;

        // 插入广告
        if (componentOptions.ad) {
            const dataKey = componentOptions.dataKey || 'allData';
            const ad = window[dataKey][componentOptions.ad];

            this.getAd(ad);
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.content !== nextProps.content) {
            const {
                content,
                componentOptions: { extendData, length },
            } = nextProps;

            this.setState({ data: this.formatData(content, extendData, length) });
        }
    }
    // 获取广告
    async getAd(ad) {
        const { componentOptions } = this.props;
        const len = this.state.data.length;
        const callback = await handleAd(ad);
        const event = new UiBaseEvent();

        callback(ad.data, event, this.insert);

        event.trigger('init', { len: len < componentOptions.length ? len : componentOptions.length, index: 0 });
    }
    insert = (insertArr, replaceArr, adFn) => {
        console.log('SliderComponent Callback.', insertArr, replaceArr, adFn);

        if (adFn && typeof adFn === 'function') {
            const { data } = this.state;

            const infoData = [...data];
            const refs = [];

            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }
    };
    // 轮播图数据处理
    formatData(data = [], extendData = [], length = 5) {
        let dLen = length;

        // 先计算extendData内的数据
        const extendDataResult = [];

        for (const item of extendData) {
            dLen = length - item.data.length;

            for (const item2 of item.data) {
                extendDataResult.push({
                    id: item2.id,
                    thumbnail: item2.thumbnail,
                    url: item2.notFormatUrl ? item2.url : formatUrl(item2.url),
                    title: item2.title,
                });
            }
        }

        // 主数据至少为1条
        if (dLen <= 0) dLen = 1;

        const mainData = [];

        for (let i = 0, j = dLen > data.length ? data.length : dLen; i < j; i++) {
            const item = data[i];

            mainData.push({
                id: item.id,
                thumbnail: item.thumbnail,
                url: item.notFormatUrl ? item.url : formatUrl(item.url),
                title: item.title,
            });
        }

        return mainData.concat(extendDataResult).slice(0, length);
    }
    // 轮播图模板
    sliderTmplView(item) {
        const url = item.url;

        if (item.type === 'ad') {
            return <div ref={item.ref} className={styles.box} />;
        } else {
            return (
                <a
                    className={styles.box}
                    href={url}
                    target="_blank"
                    rel={relText}
                    ifeng_ui_pc_sliders-link=""
                    data-innermask>
                    <img
                        className={styles.image}
                        src={formatImage(item.thumbnail, 640, 360)}
                        alt={item.title}
                        width={640}
                        height={360}
                        ifeng_ui_pc_sliders-image=""
                    />
                    <div className={styles.link} ifeng_ui_pc_sliders-title="">
                        {item.title}
                    </div>
                </a>
            );
        }
    }
    render() {
        const { componentOptions } = this.props;
        const { data } = this.state;
        const isOne = !(data.length === 1);
        const config = {
            dotCurrentStyle: styles.dotCurrent,
            autoplayInterval: 10000,
            sliderTmpl: this.sliderTmplView,
            dotsAction: 'hover',
            autoplay: isOne,
            arrows: isOne,
            dots: isOne,
            ...componentOptions,
        };

        return (
            <div className={styles.lunbo}>{data.length === 0 ? null : <Slides content={data} config={config} />}</div>
        );
    }
}

export default errorBoundary(SliderComponent);
